import { useContext } from "react";
import Booking from "./Booking";
import { LocationContext } from "../Context/LocationContext";

function Site() {

    const locationContext = useContext(LocationContext);

    return(
        <div className="site">
            <h1 className="region">{locationContext.currentLocation.name}</h1> 
            <h2 className="country">{locationContext.currentCountry.country}</h2>
            <h2>About</h2>
            <hr />
            <p className="description">
                {locationContext.currentLocation.description}
            </p>
            <Booking />
        </div>
    );

}

export default Site;