import { useContext } from "react";
import { LocationContext } from "../Context/LocationContext";

function Photos() {

    const locationContext = useContext(LocationContext);

    return(
        <div className="photos">
            <div className="space"></div>
            <h2>Photos</h2>
            <hr />
            <div className="galery">
                {locationContext.currentLocation.photos.map((pic: number, index: number) => {
                    return <img alt={locationContext.currentLocation.name} key={index} src={"./images/image_" + locationContext.currentLocation.pathName + "_" + pic + ".webp"} />
                })}
            </div>  
        </div>
    );
}

export default Photos;