import { useNavigate } from "react-router-dom";

function Legals() {

    const navigate = useNavigate();

    return(
        <div className="background-legals">
            <div className="legals">
                <div className="notice">
                    <h1>Legals notice</h1>
                    <h2>Hoisting</h2>
                    <p>
                    This site is hosted by O2Switch.fr <br />
                    Head Office : Chem. des Pardiaux, 63000 Clermont-Ferrand - France <br />
                    This host currently holds the personal information concerning the author of this site.
                    </p>
                    <h2>Responsability</h2>
                    <p>
                    The site editor provides free content. He permanently makes sure to put the means at his disposal to ensure the quality of his contents.
                    </p>
                    <h2>Intellectual Property</h2>
                    <p>
                    DevExploris is the owner of the structure. <br />
                    Images can be found here : <br />
                    - <a href="https://unsplash.com">Unsplash</a> <br/>
                    - <a href="https://pexels.com">Pexels</a>
                    </p>
                    <h2>Contractuals limitations on technical data</h2>
                    <p>
                    The site uses JavaScript. The website cannot be held responsible for any material damage related to its use. The user undertakes to navigate using recent equipment, free of viruses and with a latest generation browser. This site cannot bring you the best experience without javascript
                    </p>
                    <h2>Cookies</h2>
                    <p>
                    The user is informed that no cookies are used on this website.
                    </p>
                    <h2>Thank You</h2>
                    <a href="" onClick={() => navigate('/')}>Back to home</a><br />
                    <a href="https://devexploris.com">Back to my portfolio</a>
                </div>
            </div>
        </div>
    );

}

export default Legals;