import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from './Pages/Landing';
import Legals from './Pages/Legals';
import {LocationProvider} from './Context/LocationContext';

function App() {
  return (
    <LocationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/legals" element={<Legals />} />
        </Routes>
      </BrowserRouter>
    </LocationProvider>
  );
}

export default App;
