
function SocialNetwork() {

    const networks = ['icon_twitter.webp', 'icon_facebook.webp', 'icon_instagram.webp']

    return(
        <div className="social-network">
            <div className="line"></div>
            {networks.reverse().map((network: string, index: number) => {
                return(<img key={index} src={'./icons/' + network} alt="social" />)
            })}
            <div className="line"></div>
        </div>
    );

}

export default SocialNetwork;