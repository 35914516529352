import { useContext, useEffect, useState } from "react";
import { Country, LocationContext } from "../Context/LocationContext";


function Navigation() {

    const locationContext = useContext(LocationContext);

    const [opacity, setOpacity] = useState(true);

    function changeOpacity() {
        let opacityTmp = opacity;
        setOpacity(!opacityTmp);
    }

    useEffect(() => {
        let elements = [document.querySelector('main') as HTMLElement, document.querySelector('footer') as HTMLElement] ;
        elements.forEach(element => {
            if(opacity) {
                element.classList.add('opacity');
                element.classList.remove('opacity-none');
            } else {
                element.classList.remove('opacity');
                element.classList.add('opacity-none');
            }
        });

    }, [opacity])

    return(
        <nav className="navigation">
            <img className="icon-opacity" onMouseEnter={() => changeOpacity()} onMouseLeave={() => changeOpacity()} src={"./icons/icon_opacity_" + (opacity ? "true" : "false") + ".webp"} alt="icon-opacity"/>
            {locationContext.availableCountries.map((destination: Country, index: number) => {
                return <span onClick={() => locationContext.setNewCountry(destination)} key={index}>{destination.country} <img alt={destination.country} src={"./images/flags/" + destination.flag + ".webp"} /></span>
            })}
        </nav>
    );
}

export default Navigation;