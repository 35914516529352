import Location from "../Components/Location";
import Navigation from "../Components/Navigation";
import Photos from "../Components/Photos";
import Site from "../Components/Site";
import SocialNetwork from "../Components/SocialNetworks";

function Landing() {

    return(
        <div className="background">
            <header>
                <Navigation />
            </header>
            <main className="opacity">
                <SocialNetwork />
                <Site />
                <Photos />
            </main>
            <footer>
                <Location />
                <div className="credit">
                    <a href="https://devexploris.com">DevExploris</a>
                    2024
                    <a href="/legals">Legals Notices</a>
                </div>
            </footer>
        </div>
    );

}

export default Landing;