
function Booking() {

    return(
        <button className="booking">
            Fly away !
        </button>
    );
}

export default Booking;