import { useContext } from "react";
import { LocationContext, Region } from "../Context/LocationContext";

function Location() {

    const locationContext = useContext(LocationContext);

    return(
        <div className="locations">
            <div className="icon">
                <img alt="location" src="./icons/icon_pin.webp" />
                <div className="line"></div>
            </div>
            <div className="location">
                {locationContext.currentCountry.infos.regions.map((location : Region, index : number) => {
                    return <span onClick={() => locationContext.setNewLocation(location)} className={location.name === locationContext.currentLocation.name ? "currentLocation" : ""} key={index}>{location.name}</span>
                })}
            </div>
        </div>
    );

}

export default Location;