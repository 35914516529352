import React, { useEffect, useState } from 'react';

export interface Region {
  name: string,
  pathName: string,
  photos: number[],
  description: string
}

export interface Country {
  country: string,
  flag: string,
  infos: {
    name: string,
    regions: Region[]
  }
}

const countriesInfos = 
[{
  country: "Turkey",
  flag: "flag_turkey",
  infos: {
    name: "Turkey",
    regions: [
      {
        name : "Cappadocia",
        pathName: "cappadocia",
        photos: [1, 2, 3, 4, 5],
        description: "Discover the magic of Cappadocia, where ancient, otherworldly landscapes unfold at dawn beneath a sky dotted with vibrant hot air balloons. Soar above fairy chimneys and mystical valleys for a once-in-a-lifetime experience that feels like stepping into a dream."
      },
      {
        name : "Istanbul",
        pathName: "istanbul",
        photos: [1, 2, 3, 4, 5],
        description: "Discover Istanbul, where East meets West in a vibrant fusion of cultures, history, and flavors. Wander through stunning Byzantine and Ottoman architecture, explore bustling bazaars, and be captivated by the magic of the Bosphorus, where two continents come together in one extraordinary city."
      },
      {
        name : "Ankara",
        pathName: "ankara",
        photos: [1, 2, 3, 4, 5],
        description: "Explore Ankara, Turkey's modern capital, where history and progress blend seamlessly. Discover fascinating museums, historic landmarks, and a contemporary vibe. Dive into Turkey's rich past while enjoying the dynamic energy of a city on the rise."
      }
    ]
  }
},
{
  country: "India",
  flag: "flag_india",
  infos: {
    name: "India",
    regions: [
      {
        name : "Mumbai",
        pathName: "mumbai",
        photos: [1, 2, 3, 4, 5],
        description: "Discover the heartbeat of India in Mumbai, a city where dreams meet reality. Stroll along bustling streets lined with colonial architecture, experience the vibrant energy of Bollywood, and unwind on the Arabian Sea's shores. From historic landmarks to cutting-edge art, Mumbai is a dynamic blend of tradition and modernity."
      },
      {
        name : "New Delhi",
        pathName: "newdelhi",
        photos: [1, 2, 3, 4, 5],
        description: "Explore New Delhi, the capital of India, where ancient history meets contemporary life. Wander through Mughal-era monuments, lush gardens, and lively markets. Experience the rich cultural tapestry that blends old-world charm with modern sophistication in this bustling metropolis."
      },
      {
        name : "Jaipur",
        pathName: "jaipur",
        photos: [1, 2, 3, 4, 5],
        description: "Step into the royal past of India in Jaipur, the Pink City. Marvel at grand palaces, intricate forts, and vibrant bazaars. Let the rich colors, majestic architecture, and timeless traditions of Rajasthan transport you to a bygone era, where every corner tells a story."
      }
    ]
  }
},
{
  country: "Japan",
  flag: "flag_japan",
  infos: {
    name: "Japan",
    regions: [
      {
        name : "Tokyo",
        pathName: "tokyo",
        photos: [1, 2, 3, 4, 5],
        description: "Experience the dazzling energy of Tokyo, where cutting-edge innovation meets deep-rooted tradition. Wander through neon-lit streets, explore serene temples, and dive into the city's diverse culinary scene. From the bustling districts of Shibuya and Shinjuku to the tranquil beauty of gardens and shrines, Tokyo is a city of endless contrasts."
      },
      {
        name : "Kyoto",
        pathName: "kyoto",
        photos: [1, 2, 3, 4, 5],
        description: "Discover Kyoto, the cultural heart of Japan, where history whispers through every temple and garden. Stroll through ancient shrines, gaze at stunning cherry blossoms, and walk along the iconic bamboo groves. With its rich heritage and timeless beauty, Kyoto offers a serene escape into Japan's traditional soul."
      }
    ]
  }
},
{
  country: "China",
  flag: "flag_china",
  infos: {
    name: "China",
    regions: [
      {
        name : "Beijing",
        pathName: "beijing",
        photos: [1, 2, 3, 4, 5],
        description: "Step into the history of Beijing, the capital of China, where imperial grandeur meets contemporary innovation. Explore ancient palaces, walk along the Great Wall, and wander through vibrant hutongs. Beijing's rich cultural heritage and modern energy make it a captivating destination that bridges the past and the future."
      },
      {
        name : "Chengdu",
        pathName: "chengdu",
        photos: [1, 2, 3, 4, 5],
        description: "Immerse yourself in the charm of Chengdu, where ancient traditions meet modern vibrancy. Explore the city's lush parks, savor the bold flavors of Sichuan cuisine, and visit the famous panda sanctuaries. Chengdu's relaxed pace and rich cultural heritage make it the perfect gateway to China's timeless beauty."
      },
      {
        name : "Hong Kong",
        pathName: "hongkong",
        photos: [1, 2, 3, 4, 5],
        description: "Experience the dynamic pulse of Hong Kong, a city where towering skyscrapers blend with tranquil harbors. Discover a unique fusion of East and West, from bustling street markets to luxurious shopping districts. With its dramatic skyline and rich cultural tapestry, Hong Kong is a city of endless possibilities."
      }
    ]
  }
}
]

interface LocationContextInterface {
  currentCountry: Country;
  currentLocation: Region;
  availableRegions: Region[]
  availableCountries: Country[]
  setNewCountry: (country: Country) => void;
  setNewLocation: (location: Region) => void;
}

const LocationContext = React.createContext<LocationContextInterface>({
  currentCountry: countriesInfos[0],
  currentLocation: countriesInfos[0].infos.regions[0],
  availableRegions: countriesInfos[0].infos.regions,
  availableCountries: countriesInfos,
  setNewCountry: (country: Country) => {},
  setNewLocation: (location: Region) => {},
});

function LocationProvider({ children }: { children: React.ReactNode }) {
    const [location, setLocation] = useState<Region>(countriesInfos[0].infos.regions[0]);
    const [country, setCountry] = useState<Country>(countriesInfos[0]);
    
    const [availablelocations, setAvailableLocations] = useState(countriesInfos[0].infos.regions);
    const [availableCountries, setAvailableCountries] = useState(countriesInfos);

    
    function setNewLocation(location : Region) {
      let background = document.querySelector('.background') as HTMLElement;
      console.log(background);
      background.style.background = "linear-gradient(300deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%), url(\"./images/" + location.pathName + "_landscape.webp\")"
      background.style.backgroundRepeat = "no-repeat";
      background.style.backgroundSize = "cover";
      background.style.backgroundPosition = "bottom";
      let elements = [document.querySelector('main') as HTMLElement, document.querySelector('footer') as HTMLElement] ;
      elements.forEach(element => {
              element.classList.remove('opacity');
              element.classList.add('opacity-none');
      });
      setTimeout(() => setLocation(location), 500);
      setTimeout(() => {
        elements.forEach(element => {
          element.classList.add('opacity');
          element.classList.remove('opacity-none');
  });
      }, 1500)
    }

    function setNewCountry(country : Country) {
      setCountry(country);
      setNewLocation(country.infos.regions[0]);
    }

  function disapear() {
    let elements = [document.querySelector('main') as HTMLElement, document.querySelector('footer') as HTMLElement] ;
    elements.forEach(element => {
            element.classList.remove('opacity');
            element.classList.add('opacity-none');
    });
    setTimeout(() => {
      elements.forEach(element => {
        element.classList.add('opacity');
        element.classList.remove('opacity-none');
});
    }, 200)
  }

    return (
      <LocationContext.Provider value={{currentCountry: country, currentLocation: location, setNewLocation, setNewCountry, availableRegions: availablelocations, availableCountries: availableCountries}}>
        {children}
      </LocationContext.Provider>
    );
  };

  export {LocationContext, LocationProvider};
